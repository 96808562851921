import React from "react";
import './footer.scss';
import '../../app.scss';
import { tradingLink, loginLink } from "../../constants/variables";
import Phones from './assets/footer-phones.webp';
import Logo from '../../assets/Intervide_logo_footer.svg';
import { useSelector } from 'react-redux';
import documents from "../../assets/Docs_intervide.pdf"
// import google from './assets/btn-google-play-high-res.png';
// import apple from './assets/btn-app-store.svg';
// import langTokens from '../../languages/en.json';   // for new implementation 



const Footer = () => {
    const langTokens = useSelector((state) => state.language.data);
    const selectedLang = useSelector((state) => state.language.selected);
    const navLinks = [
        {inner: "Accounts", href: ""},
        {inner: "Platform", href: ""},
        {inner: "Trading", href: ""},
        {inner: "Company", href: ""},
        {inner: "Analytics", href: ""},
        // { inner: langTokens.HEAD_NAVLINK1, href: "/trading-platforms" },
        // { inner: langTokens.HEAD_NAVLINK7, href: "/contract-specification" },
        // { inner: langTokens.HEAD_NAVLINK10, href: "/conditions" },
        // { inner: langTokens.HEAD_NAVLINK11, href: "/deposit-withdrawals" },
        // { inner: langTokens.HEAD_NAVLINK2, href: "/market-news" },
        // { inner: langTokens.HEAD_NAVLINK8, href: "/calendar" },
        // { inner: langTokens.HEAD_NAVLINK3, href: "/cfd" },
        // { inner: langTokens.HEAD_NAVLINK4, href: "/crypto" },
        // { inner: langTokens.HEAD_NAVLINK5, href: "/forex" },
        // { inner: langTokens.HEAD_NAVLINK12, href: "/commodities" },
        // { inner: langTokens.HEAD_NAVLINK6, href: "/contacts" }
    ]
    return (
        <>
            <div className="footer-block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-md-flex align-items-center">
                            <div className="footer-block__text">
                                <h2>{langTokens.MAIN_FOOTER_BANNER_TITTLE}</h2>
                                <p>{langTokens.MAIN_FOOTER_BANNER_TEXT_1}<br /> {langTokens.MAIN_FOOTER_BANNER_TEXT_2} {langTokens.MAIN_FOOTER_BANNER_TEXT_3} </p>
                                <a href={loginLink} className={`footer-block__btn ${selectedLang === 'ru' ? "lg-btn" : ""}  md-btn `}>
                                    {langTokens.BTN_TRY}
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6 footer-block__img d-sm-none d-md-block">
                            <img alt="" src={Phones} height="339px" width="439px" />
                        </div>
                    </div>
                </div>
            </div>

            <footer className="footer">
                <div className="container">
                    <div className="row overall-footer-top">
                        
                        <div className="col-lg-10">
                            <div className="footer__menu">
                                <ul className="footer__link list-inline m-0 desk_ul">
                                    {navLinks.map((i, index) =>
                                        <li key={index}><a href={i.href}>{i.inner}</a></li>
                                    )}
                                    {/* <li><a href={process.env.PUBLIC_URL + `/assets/documents/Terms&Conditions.pdf`} target="_blank" rel="noreferrer">{langTokens.MAIN_FOOTER_DOCUMENT_TERMS}</a></li> */}
                                    {/* <li><a href={process.env.PUBLIC_URL + `/assets/documents/PrivacyPolicy.pdf`} target="_blank" rel="noreferrer">{langTokens.MAIN_FOOTER_DOCUMENT_PRIVAT}</a></li>s */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-md-3 logo_auxiliary">
                            <img alt="Intervide Trading" width="150" height="150" src={Logo} />
                        </div>
                        <div className="col-md-9">
                            <ul className="footer__link list-inline m-0 desk_ul main_ul footer__link__title">
                                <li><a>{langTokens.HEADER_INTERVIDE_ACCOUNTS}</a></li>
                                <li><a>{langTokens.HEADER_INTERVIDE_PLATFORM}</a></li>
                                <li><a>{langTokens.HEADER_INTERVIDE_TRADING}</a></li>
                                <li><a>{langTokens.HEADER_INTERVIDE_COMPANY}</a></li>
                            </ul>
                            <ul className="footer__link list-inline m-0 desk_ul main_ul">
                                <li><a href="/account-real">{langTokens.HEADER_INTERVIDE_ACCOUNTS_LIST}</a></li>
                                <li><a href="/platform-desktop">{langTokens.HEADER_INTERVIDE_PLATFORM_LIST}</a></li>
                                <li><a href="/trading-crypto-currencies">{langTokens.HEADER_INTERVIDE_TRADING_LIST}</a></li>
                                <li><a href="/Intervide-support">{langTokens.HEADER_INTERVIDE_COMPANY_LIST}</a></li>
                            </ul>
                            <ul className="footer__link list-inline m-0 desk_ul main_ul">
                                <li><a href="/account-types">{langTokens.HEADER_INTERVIDE_ACCOUNTS_LIST2}</a></li>
                                <li><a href="/platform-mobile">{langTokens.HEADER_INTERVIDE_PLATFORM_LIST2}</a></li>
                                <li><a href="/trading-currency-pair">{langTokens.HEADER_INTERVIDE_TRADING_LIST2}</a></li>
                                <li><a href="/partnership">{langTokens.HEADER_INTERVIDE_COMPANY_LIST2}</a></li>
                            </ul>
                            <ul className="footer__link list-inline m-0 desk_ul main_ul">
                                <li><a href="/account-verification">{langTokens.HEADER_INTERVIDE_ACCOUNTS_LIST3}</a></li>
                                <li><a href="/platform-webtrader">{langTokens.HEADER_INTERVIDE_PLATFORM_LIST3}</a></li>
                                <li><a href="/trading-energies">{langTokens.HEADER_INTERVIDE_TRADING_LIST3}</a></li>
                                <li><a href="/contact-us">{langTokens.HEADER_INTERVIDE_COMPANY_LIST3}</a></li>
                            </ul>
                            <ul className="footer__link list-inline m-0 desk_ul main_ul">
                                <li><a href="/account-deposit">{langTokens.HEADER_INTERVIDE_ACCOUNTS_LIST4}</a></li>
                                <li><a href="/platform-faq">{langTokens.HEADER_INTERVIDE_PLATFORM_LIST4}</a></li>
                                <li><a href="/trading-futures">{langTokens.HEADER_INTERVIDE_TRADING_LIST4}</a></li>
                                <li></li>
                            </ul>
                            <ul className="footer__link list-inline m-0 desk_ul main_ul">
                                <li><a href="/account-withdrawals">{langTokens.HEADER_INTERVIDE_ACCOUNTS_LIST5}</a></li>
                                <li></li>
                                <li><a href="/trading-indices">{langTokens.HEADER_INTERVIDE_TRADING_LIST5}</a></li>
                                <li></li>
                            </ul>
                            <ul className="footer__link list-inline m-0 desk_ul main_ul">
                                <li></li>
                                <li></li>
                                <li><a href="/trading-metals">{langTokens.HEADER_INTERVIDE_TRADING_LIST6}</a></li>
                                <li></li>
                            </ul>
                            <ul className="footer__link list-inline m-0 desk_ul main_ul">
                                <li></li>
                                <li></li>
                                <li><a href="/trading-shares">{langTokens.HEADER_INTERVIDE_TRADING_LIST7}</a></li>
                                <li></li>
                            </ul>
                        </div>
                    </div>
                    <div className="contactInfoBlock">
                      <div>
                        <a href="mailto:support@intervide.com">support@intervide.com</a>
                        <p>40 Furnival St, London EC1N 2LE United Kingdom</p>
                      </div>
                      <div>
                        <a>
                          Costumer support: 48732083068
                        </a>
                        <br />
                        <a>
                          Analytic support: 48732083307
                        </a>
                      </div>
                    </div>
                    <hr className="d-none d-lg-block" />
                    <div className="risk">
                    <b>{langTokens.FOOTER_INTEVIDE_LEGAL}:</b> {langTokens.FOOTER_INTEVIDE_LEGAL_MAIN_TEXT}
                    <div className="copyBlock">
                      <p className="m-md-none">{langTokens.MAIN_FOOTER_COPYRIGHT}</p>
                      <a className="m-md-none" target="__blank" href={documents}>{langTokens.LEGAL_DOCUMENTS}</a>
                    </div>
                    </div>
                </div>
            </footer >
        </>
    )
}

export default Footer;