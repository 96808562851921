export const tradingLink = "https://trade.intervide.com/en/sign/in"      // new implementation
export const loginLink = "https://trade.intervide.com/en/sign/up"
// export const navLinks = [
//     { inner: langTokens.HEAD_NAVLINK1, href: "/trading-platforms" },
//     { inner: langTokens.HEAD_NAVLINK2, href: "/market-news" },
//     { inner: langTokens.HEAD_NAVLINK3, href: "/cfd" },
//     { inner: langTokens.HEAD_NAVLINK4, href: "/crypto"},
//     { inner: langTokens.HEAD_NAVLINK5, href: "/forex"},
//     { inner: langTokens.HEAD_NAVLINK6, href: "/contacts"}
// ]
export const newsImageRequest = "https://trade.intervide.com/endpoint/v1/storage/";